/*Global*/
html {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box; }

img {
  max-width: 100%;
  height: auto; }

body {
  padding: 0;
  margin: 0; }

.__light {
  background-color: #EDEEF0; }

.__dark {
  background-color: #001a2b; }

.__green {
  background-color: #00AF9A;
  color: #ffffff; }

.__bordered {
  border-bottom: 1px solid #EDEEF0; }

.container-fluid {
  width: 100%; }

.container {
  margin: 0 auto;
  padding: 90px 16px; }
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px; } }

.party_img {
  margin-top: 20px; }
  @media (max-width: 1200px) {
    .party_img {
      width: 100%;
      text-align: center; } }
  .party_img svg {
    margin-left: auto;
    width: 486px;
    height: 610px; }
    @media (max-width: 900px) {
      .party_img svg {
        width: 250px;
        height: 312px; } }

.container.party {
  padding: 32px;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  @media (max-width: 1200px) {
    .container.party {
      max-width: 100%;
      text-align: center; } }

.party_text {
  text-align: left;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 636px; }
  @media (max-width: 1200px) {
    .party_text {
      max-width: 100%;
      text-align: center; } }

#CSparty {
  position: relative;
  overflow: hidden; }
  #CSparty .party-bg {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }

.album {
  background-image: url("../img/album-bg.svg");
  background-color: #00AF9A;
  -webkit-background-size: cover;
     -moz-background-size: cover;
          background-size: cover; }
  .album .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .album h2 {
    width: 100%;
    color: white;
    text-align: center; }
  .album img {
    margin: 8px;
    height: 135px;
    width: 98px; }

.tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 32px 0 16px; }
  .tabs .tab {
    margin: 8px;
    padding: 8px;
    font-size: 16px;
    color: #51555F;
    border: 1px solid #EAEDEF;
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
    cursor: pointer; }
    .tabs .tab.active {
      color: #0f191f;
      border-color: #39444A; }

.congratulations {
  text-align: left;
  font-size: 0; }

.congratulation-card {
  width: -webkit-calc(25% - 32px);
  width: -moz-calc(25% - 32px);
  width: calc(25% - 32px);
  display: inline-block;
  margin: 0 16px 48px;
  position: relative;
  overflow: hidden;
  padding-bottom: 25%; }
  @media (max-width: 1200px) {
    .congratulation-card {
      width: -webkit-calc(33% - 32px);
      width: -moz-calc(33% - 32px);
      width: calc(33% - 32px);
      padding-bottom: 33%; } }
  @media (max-width: 900px) {
    .congratulation-card {
      width: -webkit-calc(50% - 16px);
      width: -moz-calc(50% - 16px);
      width: calc(50% - 16px);
      padding-bottom: 50%;
      margin: 0 8px 48px; } }
  @media (max-width: 420px) {
    .congratulation-card {
      margin: 0 8px 16px; } }
  .congratulation-card:hover .congratulation_text {
    margin-top: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto; }
  .congratulation-card img {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }

.congratulation_text {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  margin-top: -webkit-calc(100% - 12px);
  margin-top: -moz-calc(100% - 12px);
  margin-top: calc(100% - 12px);
  background-color: #00AF9A;
  color: #FFFFFF;
  font-size: 16px;
  padding: 0 16px 16px;
  -webkit-transition: all, 300ms;
  -o-transition: all, 300ms;
  -moz-transition: all, 300ms;
  transition: all, 300ms;
  overflow-y: hidden; }
  @media (max-width: 900px) {
    .congratulation_text {
      margin-top: -webkit-calc(100% - 24px);
      margin-top: -moz-calc(100% - 24px);
      margin-top: calc(100% - 24px);
      font-size: 14px; } }
  @media (max-width: 420px) {
    .congratulation_text {
      margin-top: -webkit-calc(100% - 42px);
      margin-top: -moz-calc(100% - 42px);
      margin-top: calc(100% - 42px);
      font-size: 14px; } }
  .congratulation_text h3 {
    display: inline-block;
    font-size: 14px;
    width: 100%;
    height: 48px;
    margin-bottom: 0; }

.graph {
  font-size: 14px;
  background-color: #f8f9fb;
  text-align: left;
  padding: 32px; }
  @media (max-width: 900px) {
    .graph {
      padding: 16px; } }

.graph_subject {
  margin-bottom: 16px; }

.graph_legend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px; }
  @media (max-width: 900px) {
    .graph_legend {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }

.subject_title {
  margin-bottom: 2px; }

.legend_item {
  margin-bottom: 8px;
  margin-right: 16px; }

.legend_icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 4px; }
  .legend_icon.legend_icon__spb {
    background-color: #00AF9A; }
  .legend_icon.legend_icon__nsk {
    background-color: #ff8d30; }
  .legend_icon.legend_icon__online {
    background-color: #00afea; }

.graph_city {
  height: 14px;
  margin-bottom: 4px;
  width: 0;
  -webkit-transition: all 200ms;
  -o-transition: all 200ms;
  -moz-transition: all 200ms;
  transition: all 200ms;
  position: relative; }

.graph_city__text {
  text-align: right;
  line-height: 1;
  position: absolute;
  width: 10px;
  right: -12px;
  top: 0; }

.graph_city__spb {
  background-color: #00AF9A; }

.graph_city__nsk {
  background-color: #ff8d30; }

.graph_city__online {
  background-color: #00afea; }

.button {
  display: inline-block;
  color: #ffffff;
  background-color: #00AF9A;
  font-size: 18px;
  padding: 16px 24px;
  -webkit-border-radius: 8px;
     -moz-border-radius: 8px;
          border-radius: 8px;
  outline: none;
  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
  border: none;
  -webkit-transition: background-color 100ms, color 100ms;
  -o-transition: background-color 100ms, color 100ms;
  -moz-transition: background-color 100ms, color 100ms;
  transition: background-color 100ms, color 100ms; }
  .button:hover {
    background-color: #07866a; }
  @media (max-width: 420px) {
    .button {
      font-size: 20px; } }
  .button.button__white {
    color: #00AF9A;
    background-color: #fff; }
    .button.button__white:hover {
      background-color: #EDEEF0; }

.button__big {
  font-size: 24px;
  padding: 20px 40px; }

.button__small {
  font-size: 14px;
  padding: 6px 8px; }

.button__outline {
  color: #00AF9A;
  background-color: transparent;
  border: 2px solid #00AF9A; }
  .button__outline:hover {
    color: #FFFFFF;
    background-color: #00AF9A; }

.st0 {
  fill: #FFFFFF;
  stroke: #39444A;
  stroke-width: 1.5;
  stroke-miterlimit: 10; }

.st1 {
  fill: #00AF9A;
  stroke: #39444A;
  stroke-width: 1.5;
  stroke-miterlimit: 10; }

.st3 {
  fill: #F59D20;
  stroke: #39444A;
  stroke-width: 1.5;
  stroke-miterlimit: 10; }

.st5 {
  fill: #F59D20; }

.st7 {
  fill: #FFFFFF; }

.st15 {
  fill: #00AF9A; }

.st16 {
  fill: #39444A; }

.st6 {
  fill: none;
  stroke: #39444A;
  stroke-width: 1.5;
  stroke-miterlimit: 10; }

.st18 {
  fill: none;
  stroke: #F59D20;
  stroke-width: 1.5;
  stroke-miterlimit: 10; }

.st23 {
  fill: none;
  stroke: #31B797;
  stroke-width: 1.5;
  stroke-miterlimit: 10; }

#light_green {
  opacity: 0; }

#light_orange {
  opacity: 0; }

#CSparty.start .party-dance #b_head {
  -webkit-transform-origin: 25% 25%;
     -moz-transform-origin: 25% 25%;
      -ms-transform-origin: 25% 25%;
       -o-transform-origin: 25% 25%;
          transform-origin: 25% 25%;
  -webkit-animation: 700ms ease-in 700ms infinite alternate move_head;
     -moz-animation: 700ms ease-in 700ms infinite alternate move_head;
       -o-animation: 700ms ease-in 700ms infinite alternate move_head;
          animation: 700ms ease-in 700ms infinite alternate move_head; }

#CSparty.start .party-dance #g_head {
  -webkit-transform-origin: 67% 35%;
     -moz-transform-origin: 67% 35%;
      -ms-transform-origin: 67% 35%;
       -o-transform-origin: 67% 35%;
          transform-origin: 67% 35%;
  -webkit-animation: 700ms ease-in 0s infinite alternate move_head;
     -moz-animation: 700ms ease-in 0s infinite alternate move_head;
       -o-animation: 700ms ease-in 0s infinite alternate move_head;
          animation: 700ms ease-in 0s infinite alternate move_head; }

#CSparty.start .party-dance #g_l-hand {
  -webkit-transform-origin: 72% 38%;
     -moz-transform-origin: 72% 38%;
      -ms-transform-origin: 72% 38%;
       -o-transform-origin: 72% 38%;
          transform-origin: 72% 38%;
  -webkit-animation: 700ms ease-in 50ms infinite alternate move_head;
     -moz-animation: 700ms ease-in 50ms infinite alternate move_head;
       -o-animation: 700ms ease-in 50ms infinite alternate move_head;
          animation: 700ms ease-in 50ms infinite alternate move_head; }

#CSparty.start .party-dance #g_body {
  -webkit-transform-origin: 72% 50%;
     -moz-transform-origin: 72% 50%;
      -ms-transform-origin: 72% 50%;
       -o-transform-origin: 72% 50%;
          transform-origin: 72% 50%;
  -webkit-animation: 700ms ease-in 50ms infinite alternate g_body;
     -moz-animation: 700ms ease-in 50ms infinite alternate g_body;
       -o-animation: 700ms ease-in 50ms infinite alternate g_body;
          animation: 700ms ease-in 50ms infinite alternate g_body; }

#CSparty.start .party-dance #b_r-hand-top {
  -webkit-transform-origin: 16% 29%;
     -moz-transform-origin: 16% 29%;
      -ms-transform-origin: 16% 29%;
       -o-transform-origin: 16% 29%;
          transform-origin: 16% 29%;
  -webkit-animation: 700ms ease-in 50ms infinite alternate b_r-hand-top;
     -moz-animation: 700ms ease-in 50ms infinite alternate b_r-hand-top;
       -o-animation: 700ms ease-in 50ms infinite alternate b_r-hand-top;
          animation: 700ms ease-in 50ms infinite alternate b_r-hand-top; }

#CSparty.start .party-dance #b_r-hand-bottom {
  /*transform-origin: 10% 20%;*/
  -webkit-animation: 700ms ease-in 50ms infinite alternate b_r-hand-bottom;
     -moz-animation: 700ms ease-in 50ms infinite alternate b_r-hand-bottom;
       -o-animation: 700ms ease-in 50ms infinite alternate b_r-hand-bottom;
          animation: 700ms ease-in 50ms infinite alternate b_r-hand-bottom; }

#CSparty.start .party-dance #g_r-hand {
  /*transform-origin: 10% 20%;*/
  -webkit-animation: 700ms ease-in 50ms infinite alternate g_r-hand;
     -moz-animation: 700ms ease-in 50ms infinite alternate g_r-hand;
       -o-animation: 700ms ease-in 50ms infinite alternate g_r-hand;
          animation: 700ms ease-in 50ms infinite alternate g_r-hand; }

#CSparty.start .party-dance #g_l-leg {
  -webkit-transform-origin: 70% 52%;
     -moz-transform-origin: 70% 52%;
      -ms-transform-origin: 70% 52%;
       -o-transform-origin: 70% 52%;
          transform-origin: 70% 52%;
  -webkit-animation: 700ms ease-in 50ms infinite alternate g_l-leg;
     -moz-animation: 700ms ease-in 50ms infinite alternate g_l-leg;
       -o-animation: 700ms ease-in 50ms infinite alternate g_l-leg;
          animation: 700ms ease-in 50ms infinite alternate g_l-leg; }

#CSparty.start .party-dance #b_legs {
  -webkit-transform-origin: 30% 52%;
     -moz-transform-origin: 30% 52%;
      -ms-transform-origin: 30% 52%;
       -o-transform-origin: 30% 52%;
          transform-origin: 30% 52%;
  -webkit-animation: 700ms ease-in 50ms infinite alternate b_legs;
     -moz-animation: 700ms ease-in 50ms infinite alternate b_legs;
       -o-animation: 700ms ease-in 50ms infinite alternate b_legs;
          animation: 700ms ease-in 50ms infinite alternate b_legs; }

#CSparty.start {
  color: #ffffff; }
  #CSparty.start .party-bg {
    background-color: #0f191f;
    -webkit-transition: all, 800ms;
    -o-transition: all, 800ms;
    -moz-transition: all, 800ms;
    transition: all, 800ms; }
    #CSparty.start .party-bg #light_green {
      -webkit-transform: translate(-50px, -80px);
         -moz-transform: translate(-50px, -80px);
          -ms-transform: translate(-50px, -80px);
           -o-transform: translate(-50px, -80px);
              transform: translate(-50px, -80px);
      -webkit-animation: 700ms ease-out 0ms infinite alternate light_green;
         -moz-animation: 700ms ease-out 0ms infinite alternate light_green;
           -o-animation: 700ms ease-out 0ms infinite alternate light_green;
              animation: 700ms ease-out 0ms infinite alternate light_green; }
    #CSparty.start .party-bg #light_orange {
      -webkit-transform: translate(830px, -130px) rotate(60deg);
         -moz-transform: translate(830px, -130px) rotate(60deg);
          -ms-transform: translate(830px, -130px) rotate(60deg);
           -o-transform: translate(830px, -130px) rotate(60deg);
              transform: translate(830px, -130px) rotate(60deg);
      -webkit-animation: 700ms ease-out 700ms infinite alternate light_green;
         -moz-animation: 700ms ease-out 700ms infinite alternate light_green;
           -o-animation: 700ms ease-out 700ms infinite alternate light_green;
              animation: 700ms ease-out 700ms infinite alternate light_green; }

@-webkit-keyframes move_head {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); } }

@-moz-keyframes move_head {
  from {
    -moz-transform: rotate(0deg);
         transform: rotate(0deg); }
  to {
    -moz-transform: rotate(5deg);
         transform: rotate(5deg); } }

@-o-keyframes move_head {
  from {
    -o-transform: rotate(0deg);
       transform: rotate(0deg); }
  to {
    -o-transform: rotate(5deg);
       transform: rotate(5deg); } }

@keyframes move_head {
  from {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(5deg);
       -moz-transform: rotate(5deg);
         -o-transform: rotate(5deg);
            transform: rotate(5deg); } }

@-webkit-keyframes b_r-hand-top {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(-9deg);
            transform: rotate(-9deg); } }

@-moz-keyframes b_r-hand-top {
  from {
    -moz-transform: rotate(0deg);
         transform: rotate(0deg); }
  to {
    -moz-transform: rotate(-9deg);
         transform: rotate(-9deg); } }

@-o-keyframes b_r-hand-top {
  from {
    -o-transform: rotate(0deg);
       transform: rotate(0deg); }
  to {
    -o-transform: rotate(-9deg);
       transform: rotate(-9deg); } }

@keyframes b_r-hand-top {
  from {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(-9deg);
       -moz-transform: rotate(-9deg);
         -o-transform: rotate(-9deg);
            transform: rotate(-9deg); } }

@-webkit-keyframes b_r-hand-bottom {
  from {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  to {
    -webkit-transform: translateX(16px);
            transform: translateX(16px); } }

@-moz-keyframes b_r-hand-bottom {
  from {
    -moz-transform: translateX(0px);
         transform: translateX(0px); }
  to {
    -moz-transform: translateX(16px);
         transform: translateX(16px); } }

@-o-keyframes b_r-hand-bottom {
  from {
    -o-transform: translateX(0px);
       transform: translateX(0px); }
  to {
    -o-transform: translateX(16px);
       transform: translateX(16px); } }

@keyframes b_r-hand-bottom {
  from {
    -webkit-transform: translateX(0px);
       -moz-transform: translateX(0px);
         -o-transform: translateX(0px);
            transform: translateX(0px); }
  to {
    -webkit-transform: translateX(16px);
       -moz-transform: translateX(16px);
         -o-transform: translateX(16px);
            transform: translateX(16px); } }

@-webkit-keyframes g_r-hand {
  from {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  to {
    -webkit-transform: translateX(16px);
            transform: translateX(16px); } }

@-moz-keyframes g_r-hand {
  from {
    -moz-transform: translateX(0px);
         transform: translateX(0px); }
  to {
    -moz-transform: translateX(16px);
         transform: translateX(16px); } }

@-o-keyframes g_r-hand {
  from {
    -o-transform: translateX(0px);
       transform: translateX(0px); }
  to {
    -o-transform: translateX(16px);
       transform: translateX(16px); } }

@keyframes g_r-hand {
  from {
    -webkit-transform: translateX(0px);
       -moz-transform: translateX(0px);
         -o-transform: translateX(0px);
            transform: translateX(0px); }
  to {
    -webkit-transform: translateX(16px);
       -moz-transform: translateX(16px);
         -o-transform: translateX(16px);
            transform: translateX(16px); } }

@-webkit-keyframes g_body {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); } }

@-moz-keyframes g_body {
  from {
    -moz-transform: rotate(0deg);
         transform: rotate(0deg); }
  to {
    -moz-transform: rotate(3deg);
         transform: rotate(3deg); } }

@-o-keyframes g_body {
  from {
    -o-transform: rotate(0deg);
       transform: rotate(0deg); }
  to {
    -o-transform: rotate(3deg);
       transform: rotate(3deg); } }

@keyframes g_body {
  from {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(3deg);
       -moz-transform: rotate(3deg);
         -o-transform: rotate(3deg);
            transform: rotate(3deg); } }

@-webkit-keyframes g_l-leg {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg); } }

@-moz-keyframes g_l-leg {
  from {
    -moz-transform: rotate(0deg);
         transform: rotate(0deg); }
  to {
    -moz-transform: rotate(12deg);
         transform: rotate(12deg); } }

@-o-keyframes g_l-leg {
  from {
    -o-transform: rotate(0deg);
       transform: rotate(0deg); }
  to {
    -o-transform: rotate(12deg);
       transform: rotate(12deg); } }

@keyframes g_l-leg {
  from {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(12deg);
       -moz-transform: rotate(12deg);
         -o-transform: rotate(12deg);
            transform: rotate(12deg); } }

@-webkit-keyframes b_legs {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); } }

@-moz-keyframes b_legs {
  from {
    -moz-transform: rotate(0deg);
         transform: rotate(0deg); }
  to {
    -moz-transform: rotate(2deg);
         transform: rotate(2deg); } }

@-o-keyframes b_legs {
  from {
    -o-transform: rotate(0deg);
       transform: rotate(0deg); }
  to {
    -o-transform: rotate(2deg);
       transform: rotate(2deg); } }

@keyframes b_legs {
  from {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(2deg);
       -moz-transform: rotate(2deg);
         -o-transform: rotate(2deg);
            transform: rotate(2deg); } }

@-webkit-keyframes light_green {
  from {
    opacity: 0; }
  to {
    opacity: 0.2; } }

@-moz-keyframes light_green {
  from {
    opacity: 0; }
  to {
    opacity: 0.2; } }

@-o-keyframes light_green {
  from {
    opacity: 0; }
  to {
    opacity: 0.2; } }

@keyframes light_green {
  from {
    opacity: 0; }
  to {
    opacity: 0.2; } }

body {
  font-family: 'Fira Sans', Arial, sans-serif;
  font-size: 22px;
  line-height: 1.38;
  text-align: center; }
  @media (max-width: 1200px) {
    body {
      font-size: 18px; } }

a {
  text-decoration: none; }

p {
  margin: 0; }

h1, h2 {
  margin: 0 0 24px 0; }

h1 {
  font-size: 60px;
  font-weight: bold; }
  @media (max-width: 900px) {
    h1 {
      font-size: 40px; } }

h2 {
  font-size: 40px; }
  @media (max-width: 900px) {
    h2 {
      font-size: 32px; } }

.fotorama__arr:focus:after, .fotorama__html, .fotorama__img, .fotorama__nav__frame:focus .fotorama__dot:after, .fotorama__nav__frame:focus .fotorama__thumb:after, .fotorama__stage__frame, .fotorama__stage__shaft {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0; }

.fotorama__img {
  max-width: 99999px !important;
  max-height: 99999px !important;
  min-width: 0 !important;
  min-height: 0 !important;
  -webkit-border-radius: 0 !important;
     -moz-border-radius: 0 !important;
          border-radius: 0 !important;
  -webkit-box-shadow: none !important;
     -moz-box-shadow: none !important;
          box-shadow: none !important;
  padding: 0 !important; }

.fotorama__wrap .fotorama__grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: -moz-grab;
  cursor: grab; }

.fotorama__grabbing * {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing; }

.fotorama__spinner {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important; }

.fotorama__wrap--css3 .fotorama__arr, .fotorama__wrap--css3 .fotorama__nav__shaft, .fotorama__wrap--css3 .fotorama__stage__shaft, .fotorama__wrap--css3 .fotorama__thumb-border {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
       transform: translate3d(0, 0, 0); }

.fotorama__caption, .fotorama__nav:after, .fotorama__nav:before, .fotorama__stage:after, .fotorama__stage:before, .fotorama__wrap--css3 .fotorama__html, .fotorama__wrap--css3 .fotorama__nav, .fotorama__wrap--css3 .fotorama__spinner, .fotorama__wrap--css3 .fotorama__stage, .fotorama__wrap--css3 .fotorama__stage .fotorama__img, .fotorama__wrap--css3 .fotorama__stage__frame {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
       transform: translateZ(0); }

.fotorama__arr:focus, .fotorama__fullscreen-icon:focus, .fotorama__nav__frame {
  outline: 0; }

.fotorama__arr:focus:after, .fotorama__fullscreen-icon:focus:after, .fotorama__nav__frame:focus .fotorama__dot:after, .fotorama__nav__frame:focus .fotorama__thumb:after {
  content: '';
  -webkit-border-radius: inherit;
     -moz-border-radius: inherit;
          border-radius: inherit;
  background-color: rgba(0, 175, 234, 0.5); }

.fotorama__html, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img, .fotorama__wrap--video .fotorama__stage__shaft {
  -webkit-transform: none !important;
  -moz-transform: none !important;
   -ms-transform: none !important;
    -o-transform: none !important;
       transform: none !important; }

.fotorama__wrap--css3 .fotorama__nav__shaft, .fotorama__wrap--css3 .fotorama__stage__shaft, .fotorama__wrap--css3 .fotorama__thumb-border {
  transition-property: -webkit-transform, width;
  -webkit-transition-property: width, -webkit-transform;
  transition-property: width, -webkit-transform;
  -o-transition-property: width, -o-transform;
  -moz-transition-property: transform, width, -moz-transform;
  transition-property: transform, width;
  transition-property: transform, width, -webkit-transform, -moz-transform, -o-transform;
  -webkit-transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
     -moz-transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
       -o-transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
          transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
  -webkit-transition-duration: 0ms;
     -moz-transition-duration: 0ms;
       -o-transition-duration: 0ms;
          transition-duration: 0ms; }

.fotorama__arr, .fotorama__no-select, .fotorama__wrap {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fotorama__select {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text; }

.fotorama__nav, .fotorama__nav__frame {
  margin: auto;
  padding: 0; }

.fotorama__caption__wrap, .fotorama__nav__frame, .fotorama__nav__shaft {
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: middle;
  *display: inline;
  *zoom: 1; }

.fotorama__nav__frame, .fotorama__thumb-border {
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box; }

.fotorama__caption__wrap {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box; }

.fotorama--hidden, .fotorama__load {
  position: absolute;
  left: -99999px;
  top: -99999px;
  z-index: -1; }

.fotorama__arr, .fotorama__fullscreen-icon, .fotorama__nav, .fotorama__nav__frame, .fotorama__nav__shaft, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video-close, .fotorama__video-play {
  -webkit-tap-highlight-color: transparent; }

.fotorama__thumb {
  background-color: #7f7f7f;
  background-color: rgba(127, 127, 127, 0.2); }

@media print {
  .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__thumb-border, .fotorama__video-close, .fotorama__video-play {
    background: none !important; } }

.fotorama {
  min-width: 1px;
  overflow: hidden; }

.fotorama:not(.fotorama--unobtrusive) > *:not(:first-child) {
  display: none; }

.fotorama__wrap {
  -webkit-text-size-adjust: 100%;
  position: relative;
  direction: ltr;
  z-index: 0; }

.fotorama__wrap--rtl .fotorama__stage__frame {
  direction: rtl; }

.fotorama__nav, .fotorama__stage {
  overflow: hidden;
  position: relative;
  max-width: 100%; }

.fotorama__wrap--pan-y {
  -ms-touch-action: pan-y; }

.fotorama__wrap .fotorama__pointer {
  cursor: pointer; }

.fotorama__wrap--slide .fotorama__stage__frame {
  opacity: 1 !important; }

.fotorama__stage__frame {
  overflow: hidden; }

.fotorama__stage__frame.fotorama__active {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__stage__frame {
  display: none; }

.fotorama__wrap--fade .fotorama__fade-front, .fotorama__wrap--fade .fotorama__fade-rear, .fotorama__wrap--fade .fotorama__stage__frame.fotorama__active {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .fotorama__fade-front {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__fade-rear {
  z-index: 7; }

.fotorama__wrap--fade .fotorama__fade-rear.fotorama__active {
  z-index: 9; }

.fotorama__wrap--fade .fotorama__stage .fotorama__shadow {
  display: none; }

.fotorama__img {
  -ms-filter: "alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  border: none !important; }

.fotorama__error .fotorama__img, .fotorama__loaded .fotorama__img {
  -ms-filter: "alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1; }

.fotorama__wrap--only-active .fotorama__nav, .fotorama__wrap--only-active .fotorama__stage {
  max-width: 99999px !important; }

.fotorama__wrap--only-active .fotorama__stage__frame {
  visibility: hidden; }

.fotorama__wrap--only-active .fotorama__stage__frame.fotorama__active {
  visibility: visible; }

.fotorama__nav {
  font-size: 0;
  line-height: 0;
  text-align: center;
  display: none;
  white-space: nowrap;
  z-index: 5; }

.fotorama__nav__shaft {
  position: relative;
  left: 0;
  top: 0;
  text-align: left; }

.fotorama__nav__frame {
  position: relative;
  cursor: pointer; }

.fotorama__nav--dots {
  display: block; }

.fotorama__nav--dots .fotorama__nav__frame {
  width: 18px;
  height: 30px; }

.fotorama__nav--dots .fotorama__nav__frame--thumb, .fotorama__nav--dots .fotorama__thumb-border {
  display: none; }

.fotorama__nav--thumbs {
  display: block; }

.fotorama__nav--thumbs .fotorama__nav__frame {
  padding-left: 0 !important; }

.fotorama__nav--thumbs .fotorama__nav__frame:last-child {
  padding-right: 0 !important; }

.fotorama__nav--thumbs .fotorama__nav__frame--dot {
  display: none; }

.fotorama__dot {
  display: block;
  width: 4px;
  height: 4px;
  position: relative;
  top: 12px;
  left: 6px;
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  border: 1px solid #7f7f7f; }

.fotorama__nav__frame:focus .fotorama__dot:after {
  padding: 1px;
  top: -1px;
  left: -1px; }

.fotorama__nav__frame.fotorama__active .fotorama__dot {
  width: 0;
  height: 0;
  border-width: 3px; }

.fotorama__nav__frame.fotorama__active .fotorama__dot:after {
  padding: 3px;
  top: -3px;
  left: -3px; }

.fotorama__thumb {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%; }

.fotorama__nav__frame:focus .fotorama__thumb {
  z-index: 2; }

.fotorama__thumb-border {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  border-style: solid;
  border-color: #00afea;
  background-image: -webkit-gradient(linear, left top, right bottom, from(rgba(255, 255, 255, 0.25)), to(rgba(64, 64, 64, 0.1)));
  background-image: -webkit-linear-gradient(top left, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1));
  background-image: -moz- oldlinear-gradient(top left, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1));
  background-image: -o-linear-gradient(top left, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1));
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1)); }

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
         transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
         transform: rotate(360deg); } }

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
       transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
       transform: rotate(360deg); } }

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
      -o-transform: rotate(0);
         transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
      -o-transform: rotate(360deg);
         transform: rotate(360deg); } }

.fotorama__wrap--css3 .fotorama__spinner {
  -webkit-animation: spinner 24s infinite linear;
  -moz-animation: spinner 24s infinite linear;
    -o-animation: spinner 24s infinite linear;
       animation: spinner 24s infinite linear; }

.fotorama__wrap--css3 .fotorama__html, .fotorama__wrap--css3 .fotorama__stage .fotorama__img {
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  -moz-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: linear;
     -moz-transition-timing-function: linear;
       -o-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transition-duration: .3s;
     -moz-transition-duration: .3s;
       -o-transition-duration: .3s;
          transition-duration: .3s; }

.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img {
  -ms-filter: "alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0; }

.fotorama__select {
  cursor: auto; }

@-moz-document url-prefix() {
  .fotorama__active {
    -moz-box-shadow: 0 0 0 transparent;
         box-shadow: 0 0 0 transparent; } }

.fotorama__arr {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  margin-top: -16px; }

.fotorama__arr--prev {
  left: 2px;
  background-position: 0 0; }

.fotorama__arr--next {
  right: 2px;
  background-position: -32px 0; }

.fotorama__arr--disabled {
  pointer-events: none;
  cursor: default;
  *display: none;
  opacity: .1; }

.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr, .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
  opacity: 0; }

.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus, .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
  opacity: 1; }

.fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr, .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
  display: none; }

.fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus, .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
  display: block; }

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
  -webkit-transform: translate3d(32px, -32px, 0);
  -moz-transform: translate3d(32px, -32px, 0);
       transform: translate3d(32px, -32px, 0); }

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev:not(:focus) {
  -webkit-transform: translate3d(-48px, 0, 0);
  -moz-transform: translate3d(-48px, 0, 0);
       transform: translate3d(-48px, 0, 0); }

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
  -webkit-transform: translate3d(48px, 0, 0);
  -moz-transform: translate3d(48px, 0, 0);
       transform: translate3d(48px, 0, 0); }

.fotorama__wrap--css3 .fotorama__arr:not(:focus), .fotorama__wrap--css3 .fotorama__fullscreen-icon:not(:focus), .fotorama__wrap--css3 .fotorama__video-close:not(:focus), .fotorama__wrap--css3 .fotorama__video-play:not(:focus) {
  transition-property: -webkit-transform, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  -o-transition-property: opacity, -o-transform;
  -moz-transition-property: transform, opacity, -moz-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform, -moz-transform, -o-transform;
  -webkit-transition-duration: .3s;
     -moz-transition-duration: .3s;
       -o-transition-duration: .3s;
          transition-duration: .3s; }

.fotorama__nav:after, .fotorama__nav:before, .fotorama__stage:after, .fotorama__stage:before {
  content: "";
  display: block;
  position: absolute;
  text-decoration: none;
  top: 0;
  bottom: 0;
  width: 10px;
  height: auto;
  z-index: 10;
  pointer-events: none;
  background-repeat: no-repeat;
  -webkit-background-size: 1px 100%, 5px 100%;
     -moz-background-size: 1px 100%, 5px 100%;
          background-size: 1px 100%, 5px 100%; }

.fotorama__nav:before, .fotorama__stage:before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(25%, rgba(0, 0, 0, 0.2)), color-stop(75%, rgba(0, 0, 0, 0.3)), color-stop(75%, transparent)), radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.4), transparent);
  background-image: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), -webkit-radial-gradient(0 50%, farthest-side, rgba(0, 0, 0, 0.4), transparent);
  background-image: -moz- oldlinear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), -moz- oldradial-gradient(0 50%, farthest-side, rgba(0, 0, 0, 0.4), transparent);
  background-image: -o-linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), -o-radial-gradient(0 50%, farthest-side, rgba(0, 0, 0, 0.4), transparent);
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.4), transparent);
  background-position: 0 0, 0 0;
  left: -10px; }

.fotorama__nav.fotorama__shadows--left:before, .fotorama__stage.fotorama__shadows--left:before {
  left: 0; }

.fotorama__nav:after, .fotorama__stage:after {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(25%, rgba(0, 0, 0, 0.2)), color-stop(75%, rgba(0, 0, 0, 0.3)), color-stop(75%, transparent)), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.4), transparent);
  background-image: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), -webkit-radial-gradient(100% 50%, farthest-side, rgba(0, 0, 0, 0.4), transparent);
  background-image: -moz- oldlinear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), -moz- oldradial-gradient(100% 50%, farthest-side, rgba(0, 0, 0, 0.4), transparent);
  background-image: -o-linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), -o-radial-gradient(100% 50%, farthest-side, rgba(0, 0, 0, 0.4), transparent);
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.4), transparent);
  background-position: 100% 0, 100% 0;
  right: -10px; }

.fotorama__nav.fotorama__shadows--right:after, .fotorama__stage.fotorama__shadows--right:after {
  right: 0; }

.fotorama--fullscreen .fotorama__nav:after, .fotorama--fullscreen .fotorama__nav:before, .fotorama--fullscreen .fotorama__stage:after, .fotorama--fullscreen .fotorama__stage:before, .fotorama__wrap--fade .fotorama__stage:after, .fotorama__wrap--fade .fotorama__stage:before, .fotorama__wrap--no-shadows .fotorama__nav:after, .fotorama__wrap--no-shadows .fotorama__nav:before, .fotorama__wrap--no-shadows .fotorama__stage:after, .fotorama__wrap--no-shadows .fotorama__stage:before {
  display: none; }

.fotorama {
  max-width: 600px;
  margin: 0 auto; }
  .fotorama .fotorama__nav__frame.fotorama__active .fotorama__dot {
    border-color: #ff8d30;
    border-width: 4px; }
  .fotorama .fotorama__dot {
    border-color: #00AF9A;
    width: 0;
    height: 0;
    border-width: 4px; }
  .fotorama .fotorama__arr {
    background: url("../img/arrows.svg") no-repeat; }
  .fotorama .fotorama__arr--prev {
    background-position: 0 2px; }
  .fotorama .fotorama__arr--next {
    background-position: -32px -4px; }

.groupphoto {
  font-size: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 24px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  .groupphoto .img__big {
    display: inline-block;
    width: -webkit-calc(50% - 8px);
    width: -moz-calc(50% - 8px);
    width: calc(50% - 8px);
    margin-bottom: 16px; }
    @media (max-width: 900px) {
      .groupphoto .img__big {
        width: 100%; } }
  .groupphoto .img__small {
    display: inline-block;
    width: -webkit-calc(25% - 12px);
    width: -moz-calc(25% - 12px);
    width: calc(25% - 12px);
    height: auto;
    margin-bottom: 16px; }
    @media (max-width: 900px) {
      .groupphoto .img__small {
        width: -webkit-calc(50% - 8px);
        width: -moz-calc(50% - 8px);
        width: calc(50% - 8px); } }

.the-end {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .the-end img {
    margin-top: 60px; }
    @media (max-width: 900px) {
      .the-end img {
        max-width: 320px; } }

.video-preview {
  text-align: left;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #EDEEF0; }
  @media (max-width: 1200px) {
    .video-preview {
      text-align: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }
  .video-preview video {
    max-width: 550px; }
    @media (max-width: 1200px) {
      .video-preview video {
        margin-top: 32px; } }
    @media (max-width: 900px) {
      .video-preview video {
        max-width: 400px; } }
    @media (max-width: 420px) {
      .video-preview video {
        max-width: 300px; } }

.video-preview_wrapper {
  max-width: 600px; }
  .video-preview_wrapper .button {
    margin-top: 24px; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 32px;
  pointer-events: none; }

.modal-dialog-scrollable {
  max-height: subtract(100%, 64px); }
  .modal-dialog-scrollable .modal-content {
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.close {
  position: absolute;
  right: -30px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1; }

.modal-dialog-centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: subtract(100%, 64px); }

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  color: #000;
  pointer-events: auto;
  background-color: #fff;
  -webkit-background-clip: padding-box;
     -moz-background-clip: padding-box;
          background-clip: padding-box;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050 -backdrop;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.modal-title {
  margin-bottom: 0; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
     -moz-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }

.embed-responsive::before {
  display: block;
  content: ""; }

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.modal-dialog {
  max-width: 800px;
  padding: 32px;
  margin: 30px auto; }

.modal-body {
  position: relative;
  padding: 0; }

.close {
  position: absolute;
  right: -30px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  opacity: 1; }

.exam {
  display: inline-block;
  width: 142px;
  height: 194px;
  background: url("../img/exam.svg") no-repeat;
  margin: 48px 16px 0;
  padding-top: 16px;
  font-size: 24px;
  font-weight: bold;
  color: #0f191f; }
  .exam:hover {
    color: #00af9a; }
