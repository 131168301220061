.video-preview {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #EDEEF0;

  @media (max-width: $desktop-width) {
    text-align: center;
    justify-content: center;
    flex-direction: column;
  }

  video {
    max-width: 550px;
    @media (max-width: $desktop-width) {
      margin-top: 32px;
    }

    @media (max-width: $tablet-width) {
      max-width: 400px;
    }
    @media (max-width: $mobile-width) {
      max-width: 300px;
    }
  }
}

.video-preview_wrapper {
  max-width: 600px;

  .button {
    margin-top: 24px;
  }
}