.button {
  display: inline-block;
  color: #ffffff;
  background-color: #00AF9A;
  font-size: 18px;
  padding: 16px 24px;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  border: none;
  transition: background-color 100ms, color 100ms;

  &:hover {
    background-color: #07866a;
  }

  @media (max-width: $mobile-width) {
    font-size: 20px;
  }

  &.button__white {
    color: #00AF9A;
    background-color: #fff;

    &:hover {
      background-color: #EDEEF0;
    }
  }
}

.button__big {
  font-size: 24px;
  padding: 20px 40px;
}

.button__small {
  font-size: 14px;
  padding: 6px 8px;
}

.button__outline {
  color: #00AF9A;
  background-color: transparent;
  border: 2px solid #00AF9A;

  &:hover {
    color: #FFFFFF;
    background-color: #00AF9A;
  }
}