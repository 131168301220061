/*Global*/
html {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  padding: 0;
  margin: 0;
}

.__light {
  background-color: #EDEEF0;
}

.__dark {
  background-color: #001a2b;
}

.__green {
  background-color: #00AF9A;
  color: #ffffff;
}

.__bordered {
  border-bottom: 1px solid #EDEEF0;
}