.album {
  background-image: url("../img/album-bg.svg");
  background-color: #00AF9A;
  background-size: cover;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  h2 {
    width: 100%;
    color: white;
    text-align: center;
  }

  img {
    margin: 8px;
    height: 135px;
    width: 98px;
  }
}
