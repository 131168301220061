.party_img {
  margin-top: 20px;

  @media (max-width: $desktop-width) {
    width: 100%;
    text-align: center;
  }

  svg {
    margin-left: auto;
    width: 486px;
    height: 610px;

    @media (max-width: $tablet-width) {
      width: 250px;
      height: 312px;
    }
  }
}

.container.party {
  padding: 32px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $desktop-width) {
    max-width: 100%;
    text-align: center;
  }
}

.party_text {
  text-align: left;
  flex-grow: 1;
  max-width: 636px;
  @media (max-width: $desktop-width) {
    max-width: 100%;
    text-align: center;
  }
}

#CSparty {
  position: relative;
  overflow: hidden;

  .party-bg {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}