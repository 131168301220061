.st0 {
  fill: #FFFFFF;
  stroke: #39444A;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
}

.st1 {
  fill: #00AF9A;
  stroke: #39444A;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
}

.st3 {
  fill: #F59D20;
  stroke: #39444A;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
}

.st5 {
  fill: #F59D20;
}

.st7 {
  fill: #FFFFFF;
}

.st15 {
  fill: #00AF9A;
}

.st16 {
  fill: #39444A;
}

.st6 {
  fill: none;
  stroke: #39444A;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
}

.st18 {
  fill: none;
  stroke: #F59D20;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
}

.st23 {
  fill: none;
  stroke: #31B797;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
}

#light_green {
  opacity: 0;
}

#light_orange {
  opacity: 0;
}

#CSparty.start .party-dance {
  #b_head {
    transform-origin: 25% 25%;
    animation: 700ms ease-in 700ms infinite alternate move_head;
  }

  #g_head {
    transform-origin: 67% 35%;
    animation: 700ms ease-in 0s infinite alternate move_head;
  }

  #g_l-hand {
    transform-origin: 72% 38%;
    animation: 700ms ease-in 50ms infinite alternate move_head;
  }

  #g_body {
    transform-origin: 72% 50%;
    animation: 700ms ease-in 50ms infinite alternate g_body;
  }

  #b_r-hand-top {
    transform-origin: 16% 29%;
    animation: 700ms ease-in 50ms infinite alternate b_r-hand-top;
  }

  #b_r-hand-bottom {
    /*transform-origin: 10% 20%;*/
    animation: 700ms ease-in 50ms infinite alternate b_r-hand-bottom;
  }

  #g_r-hand {
    /*transform-origin: 10% 20%;*/
    animation: 700ms ease-in 50ms infinite alternate g_r-hand;
  }

  #g_l-leg {
    transform-origin: 70% 52%;
    animation: 700ms ease-in 50ms infinite alternate g_l-leg;
  }

  #b_legs {
    transform-origin: 30% 52%;
    animation: 700ms ease-in 50ms infinite alternate b_legs;
  }
}

#CSparty.start {
  color: #ffffff;

  .party-bg {
    background-color: #0f191f;
    transition: all, 800ms;

    #light_green {
      transform: translate(-50px, -80px);
      animation: 700ms ease-out 0ms infinite alternate light_green;
    }

    #light_orange {
      transform: translate(830px, -130px) rotate(60deg);
      animation: 700ms ease-out 700ms infinite alternate light_green;
    }
  }
}

@keyframes move_head {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(5deg);
  }
}

@keyframes b_r-hand-top {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-9deg);
  }
}

@keyframes b_r-hand-bottom {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(16px);
  }
}

@keyframes g_r-hand {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(16px);
  }
}

@keyframes g_body {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(3deg);
  }
}

@keyframes g_l-leg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(12deg);
  }
}

@keyframes b_legs {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(2deg);
  }
}

@keyframes light_green {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.2;
  }
}

