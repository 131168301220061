.graph {
  font-size: 14px;
  background-color: #f8f9fb;
  text-align: left;
  padding: 32px;

  @media (max-width: $tablet-width) {
    padding: 16px;
  }
}

.graph_subject {
  margin-bottom: 16px;
}

.graph_legend {
  display: flex;
  margin-top: 24px;

  @media (max-width: $tablet-width) {
    flex-direction: column;
  }
}

.subject_title {
  margin-bottom: 2px;
}

.legend_item {
  margin-bottom: 8px;
  margin-right: 16px;
}

.legend_icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 4px;

  &.legend_icon__spb {
    background-color: #00AF9A;
  }

  &.legend_icon__nsk {
    background-color: #ff8d30;
  }

  &.legend_icon__online {
    background-color: #00afea;
  }
}

.graph_city {
  height: 14px;
  margin-bottom: 4px;
  width: 0;
  transition: all 200ms;
  position: relative;
}

.graph_city__text {
  text-align: right;
  line-height: 1;
  position: absolute;
  width: 10px;
  right: -12px;
  top: 0;
}

.graph_city__spb {
  background-color: #00AF9A;
}

.graph_city__nsk {
  background-color: #ff8d30;
}

.graph_city__online {
  background-color: #00afea;
}