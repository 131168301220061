.tabs {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  margin: 32px 0 16px;

  .tab {
    margin: 8px;
    padding: 8px;
    font-size: 16px;
    color: #51555F;
    border: 1px solid #EAEDEF;
    border-radius: 8px;
    cursor: pointer;

    &.active {
      color: #0f191f;
      border-color: #39444A;
    }
  }
}