$mobile-width: 420px;
$tablet-width: 900px;
$desktop-width: 1200px;

.container-fluid {
  width: 100%;
}

.container {
  margin: 0 auto;
  padding: 90px 16px;
  @media (min-width: $desktop-width) {
    max-width: $desktop-width;
  }
}