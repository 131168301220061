.congratulations {
  text-align: left;
  font-size: 0;
}

.congratulation-card {
  width: calc(25% - 32px);
  display: inline-block;
  margin: 0 16px 48px;
  position: relative;
  overflow: hidden;
  padding-bottom: 25%;

  @media (max-width: $desktop-width) {
    width: calc(33% - 32px);
    padding-bottom: 33%;
  }

  @media (max-width: $tablet-width) {
    width: calc(50% - 16px);
    padding-bottom: 50%;
    margin: 0 8px 48px;
  }

  @media (max-width: $mobile-width) {
    margin: 0 8px 16px;
  }

  &:hover {
    .congratulation_text {
      margin-top: 0;
      top: 0;
      bottom: 0;
      overflow-y: auto;
    }
  }

  img {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.congratulation_text {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  margin-top: calc(100% - 12px);
  background-color: #00AF9A;
  color: #FFFFFF;
  font-size: 16px;
  padding: 0 16px 16px;
  transition: all, 300ms;
  overflow-y: hidden;

  @media (max-width: $tablet-width) {
    margin-top: calc(100% - 24px);
    font-size: 14px;
  }

  @media (max-width: $mobile-width) {
    margin-top: calc(100% - 42px);
    font-size: 14px;
  }

  h3 {
    display: inline-block;
    font-size: 14px;
    width: 100%;
    height: 48px;
    margin-bottom: 0;
  }
}