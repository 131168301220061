body {
  font-family: 'Fira Sans', Arial, sans-serif;
  font-size: 22px;
  line-height: 1.38;
  text-align: center;
  @media (max-width: $desktop-width) {
    font-size: 18px;
  }
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

h1, h2 {
  margin: 0 0 24px 0;
}

h1 {
  font-size: 60px;
  font-weight: bold;
  @media (max-width: $tablet-width) {
    font-size: 40px;
  }
}

h2 {
  font-size: 40px;
  @media (max-width: $tablet-width) {
    font-size: 32px;
  }
}