.exam {
  display: inline-block;
  width: 142px;
  height: 194px;
  background: url("../img/exam.svg") no-repeat;
  margin: 48px 16px 0;
  padding-top: 16px;
  font-size: 24px;
  font-weight: bold;
  color: #0f191f;

  &:hover {
    color: #00af9a;
  }
}