.groupphoto {
  font-size: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
  align-items: flex-start;

  .img__big {
    display: inline-block;
    width: calc(50% - 8px);
    margin-bottom: 16px;
    @media (max-width: $tablet-width) {
      width: 100%;
    }
  }

  .img__small {
    display: inline-block;
    width: calc(25% - 12px);
    height: auto;
    margin-bottom: 16px;
    @media (max-width: $tablet-width) {
      width: calc(50% - 8px);
    }
  }
}

.the-end {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-top: 60px;
    @media (max-width: $tablet-width) {
      max-width: 320px;
    }
  }
}
